._background_{
    background: url("/assets/wallpaper.png") no-repeat;
    background-size: cover;
        
    md-content{
        border-radius: 1em;
        background-color: rgba(255, 255, 255, 0.85);
    }

    md-tabs, ms-form-wizard, .dataTables_wrapper {
        border-radius: 0.5em;
    }

    .dataTables_wrapper {
        background-color: white;
    }
}

.ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y{
    width: 16px;
}

.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y{
    width: 8px;
    transition: width 0.25s;
}

.go-up-button{
    position: fixed;
    bottom: 0;
    right: 1em;
}

#go-up-button{
    &.ng-hide {
        //Efecto de difuminado
        /*opacity: 0;*/

        //Efecto de cambio tamaño
        transform: scale(0, 0);
    }

    &.ng-hide-add, &.ng-hide-remove {
        transition: all linear 0.2s;
    }
}

md-toast{
    &.md-error-theme .md-toast-content{
        background-color: rgba(193, 0, 0, 0.9);
    }

    &.md-success-theme .md-toast-content{
        background-color: green;
    }

    &.md-info-theme .md-toast-content{
        background-color: #B2EBF2;
        color: #0d47a1;
    }

    &.md-warn-theme .md-toast-content{
        background-color: #FFA000;
        color: #1f1f1f;
    }    
}

md-dialog {
    max-height: 100vh
}

md-select-menu {
    max-height: 95vh;
    
    md-content {
        max-height: 100%;
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {
    .page-layout.simple.fullwidth > .content{
        padding:4px;
    }
}