#cajeros{
    .cajero, .info{
        border-radius: 1em;
        margin: 0.5em 1em;
        align-items: center;

        md-fab-speed-dial{
            &.md-is-open{
                md-fab-trigger{
                    opacity: 0.2;
                }
            }
        }

        md-checkbox:not(.md-checked) .md-icon,
            md-checkbox:not([disabled]):not(.md-checked) .md-icon {
            border-color: rgba(255, 255, 255, 0.74);
        }

        &:before {
            min-height: 0;
        }
    }

    .info {
        table {
            width: 100%;
            border-collapse: collapse;
            margin: 4px 0;

            td, th {
                border: 1px solid #ffffff2e;
                padding: 2px 4px;

                img {
                    background-color: white;
                }

                &:last-child {
                    width: 0;
                }
            }

            .html-container * {
                font-size: 14px !important;
            }

            .inner-table {
                padding: 2px;

                table {
                    margin: 0;

                    td {
                        background: #d3d3d338;
                        border: none;
                    }
                }
            }
        }
    }

    .extended {
        .cajero {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            margin-bottom: 0;
        }

        .info {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            margin-top: 0;
        }
    }

    .features{
        > md-icon{            
            font-size:40px;
            height: 40px;
            width: 40px;
            line-height: 40px;
            opacity: 0.8;
        }

        > md-icon:hover{
            opacity: 1;
        }
    }

    .phone{
        color: lightslategrey;
    }

    .team-container{
        border: 3px solid #c33737;
        padding: 0;
        margin: 8px;
        border-radius: 8px;
        padding-bottom: 0.5em;

        .title{
            font-size: 18px;
            padding: 8px;
            margin-bottom: 0.5em;
        }
    }
}

#editarCajero{
    .hint {
        /* position: absolute; */
        left: 2px;
        right: auto;
        bottom: 7px;
        font-size: 12px;
        line-height: 14px;
        -webkit-transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
        color: grey;
        margin-top: 4px;
    }

    md-chips input {
        background: #d3d3d35e;
    }
}

#descCajero {
    width: 100%;
}

#codeCajero {
    md-chips input {
        background: #d3d3d35e;
    }

    .file-upload-container {
        padding: 8px;

        * {
            margin-right: 8px;
        }
    }
}