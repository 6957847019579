#usuarios{
    .team-container{
        border: 3px solid rgb(57,73,171);
        padding: 0;
        margin: 8px;
        border-radius: 8px;
        padding-bottom: 0.5em;

        .title{
            font-size: 18px;
            padding: 8px;
            margin-bottom: 0.5em;
        }
    }


    .usuario{
        border-radius: 1em;
        margin: 0.5em 1em;
        align-items: center;

        .icon-star{
            color: gold;
            margin: auto;
            margin-left: 3px;
        }

        .md-avatar{
            border-radius: 4px;
        }

        md-fab-speed-dial{
            position: absolute;
            right: 0;
            top: 0;

            &.md-is-open{
                md-fab-trigger{
                    opacity: 0.2;
                }
            }
        }

        .md-button.md-no-style{
            border-radius: 1em;
        }
    }
    
    .user-disabled {
        background: #9E9E9E !important;
        opacity: 0.5;
    }
}

#editarUsuario{
    md-dialog-content{
        padding: 24px;

        .image-container{
            cursor: pointer;
            position: relative;

            .tooltip{
                background: grey;
                z-index: 999;
                display: none;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0.8;
            }

            &:hover .tooltip{
                display: block
            }
        }

        .icon-star{
            margin: auto;
            margin-right: 8px;
            cursor: pointer;
        }

        .star-active{
            color: gold;            
        }
    }
}

#verUsuario{
    .image{
        height: 152px;
        width: 152px;
        margin-right: 16px;

        img{
            max-height: 100%;
            max-width: 100%;
        }
    }

    .md-subhead{
        color: rgba(0,0,0,0.54);
        padding-top: 0;
        font-size: 14px;
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {
    #verUsuario {
        .md-headline{
            align-self: center;
        }

        .image{
            margin-right: 0;
            margin-bottom: 16px;
        }
    }
}