#layout-horizontal-navigation {

    #toolbar {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        .navigation-toggle {
            height: $toolbarHeight;
            border-right: 1px solid rgba(0, 0, 0, 0.12);
            margin-right: 16px;

            .md-button {
                margin: 0;
                width: $toolbarHeight;
                height: $toolbarHeight;
            }
        }

        .logo {
            padding-left: 16px;

            .logo-image {
                display: block;
                /*background: material-color('light-blue', '600');*/
                width: 32px;
                min-width: 32px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                color: #FFFFFF;
                font-size: 16px;
                font-weight: 500;
                border-radius: 2px;
                
                md-icon {
                    display: block;
                    margin: 4px;
                }
            }

            .logo-text {
                color: rgba(0, 0, 0, 0.87);
                margin-left: 16px;
                font-size: 16px;
            }
        }
        
        .avatar{
            max-width: 48px;
            max-height: 48px;
            width: auto;
            height: auto;
            min-width: auto;
        }
    }
}