#places{
    .place{
        border-radius: 1em;
        margin: 0.5em 1em;
        padding: 0 56px 0 0;
        align-items: center;
        
        .md-list-item-text{
            h3, h4, span {
                margin: 8px 0;
            }
        }

        md-fab-speed-dial{
            position: absolute;
            right: 0;
            top: 0;

            &.md-is-open{
                md-fab-trigger{
                    opacity: 0.2;
                }
            }
        }

        .indicador{
            margin: 0 4px;

            .icon-sync, .icon-sync-alert{
                font-size: 36px;
                width: 36px;
                height: 36px;
                line-height: 36px;
            }

            .icon-sync{
                color: green;
            }

            .icon-sync-alert{
                color: red;
            }
        }
    }
}

#ver-usuarios{    
    .team-container{
        border: 3px solid rgb(57,73,171);
        padding: 0;
        margin: 8px;
        border-radius: 8px;

        .title{
            font-size: 18px;
            padding: 8px;
        }
    }

    table{
        border-collapse: collapse;
        width: 100%;

        td {
            padding: 2px;
            align-items: center;

            .md-avatar{
                border-radius: 4px;
                width: 40px;
                height: 40px;
            }

            md-checkbox {
                margin-bottom: 0;

                .md-label {
                    display: none;
                }
            }

            h3 {
                margin: 0 0 0 4px;
            }
        }

        td:first-of-type {
            width: 40px;
            height: 40px;
            padding: 2px 10px;
        }

        tr:nth-child(even){
            background-color: #f2f2f2;
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {
    #ver-usuarios {
        md-dialog-content{
            padding: 2px;
        }

        h3{
            font-size: 12px;
        }
        
        md-select-value {
            font-size: 12px;
        }
    }
}